import { jsx, jsxs } from 'react/jsx-runtime.js';
import { CenteredContentLayout } from '../CenteredContentLayout/CenteredContentLayout.js';
import { Layout } from '../Layout/Layout.js';
import { SupportLink } from '../SupportLink/SupportLink.js';
import { Text } from '../Typography/Typography.js';
import supportedBrowsers from '../../supportedBrowsers.js';
import styles from './UnsupportedBrowserPage.module.css.js';

const IS_BROWSER_SUPPORTED = navigator?.userAgent ? supportedBrowsers.test(navigator.userAgent) : false;
const UnsupportedBrowserPage = () => {
  return jsx(Layout, {
    className: styles.container,
    children: jsx(CenteredContentLayout, {
      title: 'Qodana has dropped support for old browser versions',
      content: jsxs("div", {
        className: styles.subtitle,
        children: [jsx(Text, {
          children: "Please, consider updating your browser, or "
        }), jsx(SupportLink, {
          label: 'contact Qodana support'
        }), jsx(Text, {
          children: " if updating the browser is not a convenient option for you."
        })]
      }),
      isChildrenCentered: true
    })
  });
};

export { IS_BROWSER_SUPPORTED, UnsupportedBrowserPage };
