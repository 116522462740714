import { jsxs, jsx } from 'react/jsx-runtime.js';
import cn from 'classnames';
import { useState, useEffect, useCallback } from 'react';
import { H3 } from '@jetbrains/ring-ui-built/components/heading/heading.js';
import { getParameterFromUrl, addParameterToUrl } from '../../utils/url.js';
import typographyStyles from '../Typography/Typography.module.css.js';
import { Orientation, DEFAULT_SIZE } from './constants.js';
import { getStepPosition } from './helpers/getStepPosition.js';
import { HorizontalContent } from './HorizontalContent/HorizontalContent.js';
import { Step } from './Step/Step.js';
import { StepContent } from './StepContent/StepContent.js';
import { TestAnchors } from './test.js';
import { VerticalContent } from './VerticalContent/VerticalContent.js';
import styles from './ProgressBar.module.css.js';

const STEP_URL_PARAM = 'step';
const STATE_URL_PARAM = 'progress';
const ProgressBar = props => {
  const {
    steps,
    showLabel = false,
    showNumbers = false,
    showTitles = false,
    orientation = Orientation.horizontal,
    persistInUrl,
    initialState
  } = props;
  const stepFromUrl = Number(getParameterFromUrl(STEP_URL_PARAM, undefined, false)) - 1;
  const [currentIndex, setCurrentIndex] = useState(stepFromUrl > 0 && stepFromUrl < steps.length ? stepFromUrl : 0);
  const stateFromUrl = getParameterFromUrl(STATE_URL_PARAM);
  const [state, setState] = useState(() => {
    if (stateFromUrl || initialState) {
      return {
        ...initialState,
        ...stateFromUrl
      };
    } else {
      return {};
    }
  });
  useEffect(() => {
    if (persistInUrl) {
      addParameterToUrl(STATE_URL_PARAM, JSON.stringify(state));
    }
  }, [state, persistInUrl]);
  const handleSetStateByKey = useCallback((key, value) => {
    setState(prevState => ({
      ...prevState,
      [key]: value
    }));
  }, []);
  const handleGetStateByKey = useCallback(key => {
    return state[key];
  }, [state]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentOnNextHandler, setCurrentOnNextHandler] = useState(null);
  const clearCurrentHandler = useCallback(() => {
    setCurrentOnNextHandler(null);
  }, []);
  const handleNextClick = useCallback(skip => {
    const goToNextStep = () => {
      setCurrentIndex(prev => prev + 1);
    };
    if (currentOnNextHandler) {
      setIsLoading(true);
      return currentOnNextHandler(skip).then(() => {
        clearCurrentHandler();
        goToNextStep();
      }).catch(() => {}).finally(() => {
        setIsLoading(false);
      });
    } else {
      goToNextStep();
      return Promise.resolve();
    }
  }, [clearCurrentHandler, currentOnNextHandler]);
  const handleSetCurrentOnNextHandler = useCallback(onNextHandler => {
    setCurrentOnNextHandler(() => onNextHandler);
  }, []);
  const renderStepContentWithHandlers = index => {
    return jsx(StepContent, {
      steps: steps,
      index: index,
      onCurrentIndexChange: setCurrentIndex,
      setStateByKey: handleSetStateByKey,
      getStateByKey: handleGetStateByKey,
      setOnNextHandler: handleSetCurrentOnNextHandler,
      onCurrentHandlerClear: clearCurrentHandler,
      disabled: isLoading,
      onNext: handleNextClick
    });
  };
  useEffect(() => {
    if (!persistInUrl) {
      return;
    }
    addParameterToUrl(STEP_URL_PARAM, (currentIndex + 1).toString(), false);
  }, [currentIndex, persistInUrl]);
  const size = props.size ? {
    ...DEFAULT_SIZE,
    ...props.size
  } : DEFAULT_SIZE;
  const vertical = orientation === Orientation.vertical;
  return jsxs("section", {
    className: styles.section,
    children: [showLabel && jsxs("header", {
      children: [jsx("span", {
        className: cn(typographyStyles.text, styles.secondaryText),
        "data-qd-test": TestAnchors.stepNumber,
        children: `Step ${currentIndex + 1} from ${steps.length}`
      }), jsx(H3, {
        className: styles.stepLabel,
        "data-qd-test": TestAnchors.stepLabel,
        children: steps[currentIndex].label
      })]
    }), jsx("div", {
      className: cn(styles.container, {
        [styles.vertical]: vertical
      }),
      children: steps.map((_ref, index) => {
        let {
          key,
          label
        } = _ref;
        const position = getStepPosition(currentIndex, index);
        const isLast = index === steps.length - 1;
        const stepNumber = showNumbers ? index + 1 : undefined;
        return jsxs("div", {
          className: styles.step,
          children: [jsx(Step, {
            position: position,
            size: size,
            vertical: vertical,
            stepNumber: stepNumber,
            isLast: isLast
          }), vertical && jsx(VerticalContent, {
            label: label,
            position: position,
            size: size,
            children: renderStepContentWithHandlers(index)
          })]
        }, key);
      })
    }), !vertical && jsx(HorizontalContent, {
      steps: steps,
      currentIndex: currentIndex,
      showTitles: showTitles,
      children: renderStepContentWithHandlers(currentIndex)
    })]
  });
};

export { ProgressBar, STEP_URL_PARAM };
