import { jsxs, jsx } from 'react/jsx-runtime.js';
import cn from 'classnames';
import FocusLock from 'react-focus-lock';
import Popup from '@jetbrains/ring-ui-built/components/popup/popup.js';
import { Directions } from '@jetbrains/ring-ui-built/components/popup/popup.consts.js';
export { Directions } from '@jetbrains/ring-ui-built/components/popup/popup.consts.js';
import 'react';
import typographyStyles from '../../Typography/Typography.module.css.js';
import { useSubscribeMenuItems } from '../useSubscribeMenuItems.js';
import styles from './SubmenuPopup.module.css.js';

function SubmenuPopup(_ref) {
  let {
    className,
    children,
    directions = [Directions.BOTTOM_CENTER],
    menu,
    open,
    onClose
  } = _ref;
  const subitems = useSubscribeMenuItems(menu);
  if (subitems.length === 0) {
    return null;
  }
  return jsxs("div", {
    className: cn(className, styles.container),
    children: [children, jsx(Popup, {
      className: styles.submenuPopup,
      directions: directions,
      hidden: !open,
      onEscPress: onClose,
      onOutsideClick: onClose,
      children: jsx(FocusLock, {
        returnFocus: true,
        children: jsx("ul", {
          className: styles.submenuItemList,
          children: subitems.map(item => jsx("li", {
            children: item.type === 'normal' ? jsx("button", {
              className: cn(typographyStyles.text, styles.submenuItem),
              onClick: item.click,
              disabled: item.enabled === false,
              children: item.label
            }) : item.type === 'link' ? jsx("a", {
              className: cn(typographyStyles.text, styles.submenuItem),
              href: item.link,
              children: item.label
            }) : null
          }, item.id || item.label))
        })
      })
    })]
  });
}

export { SubmenuPopup };
