import { BaseQueryFn, FetchBaseQueryError } from '@reduxjs/toolkit/query'
import { createApi, FetchArgs, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { showError } from '@qodana/blocks/lib/services'

import { FetchErrorNotification } from '../../components/FetchErrorNotification/FetchErrorNotification'
import { QueryErrorAlertType } from '../../config/runtime'

type APIErrorMessage = {
	name: string
	details: string
}

function createDataApi(
	baseUrl: string,
	queryReportAlertType: QueryErrorAlertType,
	suppressedErrors: string[],
) {
	function isSuppressedError(error: FetchBaseQueryError) {
		try {
			return suppressedErrors.includes((error.data as APIErrorMessage).name)
		} catch {
			return false
		}
	}

	const baseQuery = fetchBaseQuery({
		baseUrl,
		credentials: 'include',
	})

	const baseQueryWithAlertingService: BaseQueryFn<
		string | FetchArgs,
		unknown,
		FetchBaseQueryError
	> = async (args, api, extraOptions) => {
		let result = await baseQuery(args, api, extraOptions)
		if (result.error && !isSuppressedError(result.error)) {
			switch (queryReportAlertType) {
				case 'ui':
					showError(
						<FetchErrorNotification
							status={result.error.status}
							data={result.error.data}
							url={result.meta?.request.url}
						/>,
					)
					break
				case 'console':
					console.error(result.error)
					break
			}
		}

		return result
	}

	return createApi({
		reducerPath: 'dataApi',
		baseQuery: baseQueryWithAlertingService,
		tagTypes: [
			'ManagedCustomer',
			'Project',
			'ProjectToken',
			'Organization',
			'OrganizationUser',
			'Report',
			'Misc',
			'User',
			'UserAgreement',
			'Team',
			'TeamUser',
			'UserPermission',
			'Logs',
			'ProjectBranches',
			'ProjectSetupState',
			'OrganizationVCSAccountList',
		],
		endpoints: (builder) => ({
			getReportFiles: builder.query<
				{ files: { file: string; url: string }[] },
				{ reportId: string; files: string[] }
			>({
				query: ({ reportId, files }) => ({
					url: `reports/${reportId}/files?paths=${encodeURIComponent(files.join(','))}`,
				}),
			}),
		}),
	})
}

let _dataApi: ReturnType<typeof createDataApi>

export function initializeDataApi(
	baseUrl: string,
	queryReportAlertType: QueryErrorAlertType,
	suppressedErrors: string[],
) {
	_dataApi = createDataApi(baseUrl, queryReportAlertType, suppressedErrors)
}

export default function getDataApi() {
	if (typeof _dataApi === 'undefined') {
		throw new Error('DataApi not initialized yet!')
	}

	return _dataApi
}
