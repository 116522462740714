import { jsx } from 'react/jsx-runtime.js';
import { Directions } from '@jetbrains/ring-ui-built/components/popup/popup.consts.js';
import cn from 'classnames';
import { useState, useRef, useEffect } from 'react';
import { Button } from '@jetbrains/ring-ui-built/components/button/button.js';
import CopyIcon from '@jetbrains/icons/copy.js';
import Tooltip from '@jetbrains/ring-ui-built/components/tooltip/tooltip.js';
import { showError } from '../../services/alert/alert.js';
import '../../services/telemetry/telemetry.js';
import { copyToClipboard } from '../../utils/copyToClipboard.js';
import styles from './CopyButton.module.css.js';
import { TestAnchors } from './test.js';

const SHOW_COPIED_STATE_TIME = 2000;
const COPY_ERROR_TEXT = 'Failed to copy';
const TOOLTIP_POPUP_PROPS = {
  className: styles.popup,
  top: 0,
  directions: [Directions.TOP_CENTER],
  'data-test': TestAnchors.copyButtonTooltip
};
const CopyButton = props => {
  const {
    textToCopy,
    children,
    iconClassName,
    className,
    showTooltipOnlyOnCopy,
    containerClassName,
    onCopy,
    ...buttonProps
  } = props;
  const [isTextCopied, setIsTextCopied] = useState(false);
  const timerRef = useRef(null);
  useEffect(() => {
    return () => {
      const timeoutId = timerRef.current;
      if (timeoutId !== null) {
        clearTimeout(timeoutId);
      }
    };
  }, []);
  const handleCopyClick = async e => {
    if (textToCopy) {
      e.stopPropagation();
      if (onCopy) {
        onCopy();
      }
      try {
        await copyToClipboard(textToCopy);
        setIsTextCopied(true);
        timerRef.current = setTimeout(() => setIsTextCopied(false), SHOW_COPIED_STATE_TIME);
      } catch {
        showError(COPY_ERROR_TEXT);
      }
    } else {
      showError(COPY_ERROR_TEXT);
    }
  };
  const showTooltip = showTooltipOnlyOnCopy ? isTextCopied : true;
  const title = isTextCopied ? 'Copied' : 'Copy';
  const Wrapper = showTooltip ? Tooltip : 'span';
  const wrapperProps = showTooltip ? {
    popupProps: TOOLTIP_POPUP_PROPS,
    title
  } : undefined;
  return jsx(Wrapper, {
    className: containerClassName,
    ...wrapperProps,
    children: jsx(Button, {
      "data-qd-test": TestAnchors.copyButton,
      onClick: handleCopyClick,
      text: true,
      className: cn(className, styles.button),
      icon: CopyIcon,
      iconClassName: iconClassName,
      ...buttonProps,
      children: children
    })
  });
};

export { CopyButton };
