import _defineProperty from '@babel/runtime/helpers/defineProperty';
import 'core-js/modules/es.array.push.js';
import { removeKeysFromObj } from './object.js';

function createLocalStorageManager(storageKey) {
  return new StorageManager(localStorage, storageKey);
}
function createSessionStorageManager(storageKey) {
  return new StorageManager(sessionStorage, storageKey);
}
class StorageManager {
  constructor(storage, storageKey) {
    _defineProperty(this, "LOCAL_STORAGE_KEY", void 0);
    // use '.' only to distinct version
    _defineProperty(this, "versionDelimiter", '.');
    _defineProperty(this, "storage", void 0);
    _defineProperty(this, "storageType", void 0);
    _defineProperty(this, "getFromStorage", unitName => {
      try {
        const qodanaStoreStr = this.storage.getItem(this.LOCAL_STORAGE_KEY);
        if (qodanaStoreStr === null) {
          return null;
        }
        const qodanaStore = JSON.parse(qodanaStoreStr);
        return qodanaStore[unitName];
      } catch (e) {
        console.error(`getFromStorage, can't get data from ${this.storageType} storage: `, e.message);
        return null;
      }
    });
    _defineProperty(this, "saveToStorage", (unitName, data) => {
      try {
        const qodanaStoreStr = this.storage.getItem(this.LOCAL_STORAGE_KEY);
        const qodanaStore = qodanaStoreStr !== null ? JSON.parse(qodanaStoreStr) : {};
        const unitOlderKeys = this.generateOlderKeys(unitName);
        const clearedStore = removeKeysFromObj(qodanaStore, unitOlderKeys);
        clearedStore[unitName] = data;
        this.storage.setItem(this.LOCAL_STORAGE_KEY, JSON.stringify(clearedStore));
        return true;
      } catch (e) {
        console.error(`saveToStorage, can't save data to ${this.storageType} storage: `, e.message);
        return false;
      }
    });
    _defineProperty(this, "removeUnitFromStorage", unitName => {
      try {
        const qodanaStoreStr = this.storage.getItem(this.LOCAL_STORAGE_KEY);
        const qodanaStore = qodanaStoreStr !== null ? JSON.parse(qodanaStoreStr) : {};
        delete qodanaStore[unitName];
        this.storage.setItem(this.LOCAL_STORAGE_KEY, JSON.stringify(qodanaStore));
      } catch (e) {
        console.error(`removeUnitFromStorage, can't remove data from ${this.storageType} storage: `, e.message);
      }
    });
    _defineProperty(this, "cleanObsoleteStorage", () => {
      try {
        const qdOlderKeys = this.generateOlderKeys(this.LOCAL_STORAGE_KEY);
        for (const oldKey of qdOlderKeys) {
          this.storage.removeItem(oldKey);
        }
      } catch (e) {
        console.error(`cleanObsoleteStorage, can't remove obsolete data from ${this.storageType} storage: `, e.message);
      }
    });
    _defineProperty(this, "generateOlderKeys", versionedStr => {
      const result = [];
      const split = versionedStr.split(this.versionDelimiter);
      const version = Number(split.find(el => el[0] === 'v' && isFinite(Number(el.slice(1))))?.slice(1));
      for (let i = 1; i < version; i++) {
        if (versionedStr.indexOf(`.v${version}.`) !== -1) {
          result.push(versionedStr.replace(`.v${version}.`, `.v${i}.`));
        }
      }
      return result;
    });
    this.LOCAL_STORAGE_KEY = storageKey;
    this.storage = storage;
    this.storageType = storage === localStorage ? 'local' : 'session';
  }
}

export { StorageManager, createLocalStorageManager, createSessionStorageManager };
