const capitaliseStr = str => {
  if (!str) {
    return '';
  }
  for (let i = 1; i < str.length + 1; i++) {
    if (str[i - 1].toLowerCase() !== str[i - 1].toUpperCase()) {
      return str.slice(0, i).toUpperCase() + str.slice(i).toLowerCase();
    }
  }
  return str;
};
const insertSpacesInStr = function (str) {
  let interval = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 3;
  const reversedArray = str.split('').reverse();
  if (interval <= 0) {
    return str;
  }
  for (let i = interval; i < reversedArray.length; i += interval) {
    reversedArray.splice(i, 0, ' ');
    // noinspection AssignmentToForLoopParameterJS
    i++;
  }
  return reversedArray.reverse().join('');
};
const getShortStr = (str, charLim) => {
  return charLim > 2 && str.length > charLim ? `${str.slice(0, charLim - 3)}...` : str;
};
const getHashCode = s => {
  return s.split('').reduce((a, b) => {
    const c = (a << 5) - a + b.charCodeAt(0);
    return c & c;
  }, 0);
};

export { capitaliseStr, getHashCode, getShortStr, insertSpacesInStr };
